import Vue from "vue";
import VueRouter from "vue-router";
import FastPay from "@/views/FastPay";
import TipsPage from "@/views/TipsPage";
import StoreView from "@/views/StoreView";
import ResultView from "@/views/ResultView";
import AuthPage from "@/views/menu/AuthPage";
import MenuPage from "@/views/menu/MenuPage";
import AccountPage from "@/views/AccountPage";
import OfflinePage from "@/views/OfflinePage";
import OrderPage from "@/views/OrderPage.vue";
import StoreViewPay from "@/views/StoreViewPay";
import FastView from "@/views/fastview/FastView";
import DesksPage from "@/views/menu/DesksPage.vue";
import CatalogPage from "@/views/menu/CatalogPage";
import DeliveryPage from "@/views/menu/DeliveryPage";
import TakeawayPage from "@/views/menu/TakeawayPage";
import FavoritePage from "@/components/menu/FavoritePage";
import MenuAccountPage from "@/views/menu/MenuAccountPage";
import MarketplacePage from "@/views/menu/MarketplacePage";
import MarketAccountPage from "@/views/MarketAccountPage.vue";
import CheckViewRedirect from "@/views/CheckViewRedirect.vue";
import MarketplaceDetail from "@/views/menu/MarketplaceDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MarketplacePage",
    component: MarketplacePage,
  },
  {
    path: "/market-org",
    name: "MarketplacePage",
    component: MarketplacePage,
  },
  {
    path: "/app-auth",
    name: "AuthPage",
    component: AuthPage,
  },
  {
    path: "/market/account",
    name: "MarketAccountPage",
    component: MarketAccountPage,
  },
  {
    path: "/menu/account",
    name: "MenuAccountPage",
    component: MenuAccountPage,
  },
  {
    path: "/account",
    name: "AccountPage",
    component: AccountPage,
  },
  {
    path: "/order/:desk?",
    name: "OrderPage",
    component: OrderPage,
  },
  {
    path: "/s/:storeId",
    name: "StoreView",
    component: StoreView,
  },
  // {
  //   path: "/s/:storeId",
  //   name: "StoreView",
  //   component: StoreViewRedirect,
  // },
  {
    path: "/s-pay/:storeId",
    name: "StoreViewPay",
    component: StoreViewPay,
    meta: {
      pageType: 'menu'
    }
  },
  {
    path: "/d/:brand/:store?",
    name: "DeliveryPage",
    component: DeliveryPage,
  },
  {
    path: "/m/:brand/:desk",
    name: "MenuPage",
    component: MenuPage,
  },
  {
    path: "/m/:brand",
    name: "DesksPage",
    component: DesksPage,
  },
  {
    path: "/t/:brand/:store",
    name: "TakeawayPage",
    component: TakeawayPage,
  },
  {
    path: "/market/:brand/:store?",
    name: "MarketplaceDetail",
    component: MarketplaceDetail,
  },
  {
    path: "/catalog/:brand/:store?",
    name: "CatalogPage",
    component: CatalogPage,
  },
  {
    path: "/pos/:storeId",
    name: "CheckViewRedirect",
    component: CheckViewRedirect,
  },
  {
    path: "/p/:storeId",
    name: "CheckView",
    component: () => import("../views/CheckView"),
  },
  // {
  //   path: "/p/:store",
  //   name: "PreCheck",
  //   component: PreCheckRedirect,
  // },
  // {
  //   path: "/p-old/:store",
  //   name: "PreCheck",
  //   component: () => import("../views/precheck/PreCheck.vue"),
  // },
  {
    path: "/r/:storeId/:orderId?",
    name: "StoreReview",
    component: () => import("../views/StoreReview.vue"),
  },
  {
    path: "/r/:store/:order?",
    name: "ReviewPage",
    component: () => import("../views/ReviewPage.vue"),
  },
  // {
  //   path: "/r/:store/:order?",
  //   name: "ReviewPageRedirect",
  //   component: ReviewPageRedirect,
  // },
  {
    path: "/offline",
    name: "OfflinePage",
    component: OfflinePage,
  },
  {
    path: "/demo",
    name: "DemoPage",
    component: () => import('@/views/DemoView'),
  },
  {
    path: "/multikassa",
    name: "MultikassaPage",
    component: () => import('@/views/MultikassaView'),
  },
  {
    path: "/application-links",
    name: "ApplicationLinksPage",
    component: () => import('@/views/ApplicationLinksView'),
  },
  {
    path: "/aktsiya",
    name: "AktsiyaPage",
    component: () => import('@/views/PromotionRedirect'),
  },
  {
    path: "/b/:storeId",
    name: "BookTable",
    component: () => import("@/components/menu/BookTable"),
  },
  {
    path: "/b/:storeId/:id",
    name: "BookList",
    component: () => import("@/components/menu/BookList"),
  },
  {
    path: "/result",
    name: "ResultView",
    component: ResultView,
  },
  // {
  //   path: "/result",
  //   name: "ResultView",
  //   component: ResultViewRedirect,
  // },
  {
    path: "/favorite",
    name: "FavoritePage",
    component: FavoritePage,
  },
  {
    path: "/y/:storeId",
    name: "FastView",
    component: FastView
  },
  // {
  //   path: "/y/:storeId",
  //   name: "FastView",
  //   component: FastViewRedirect
  // },
  // {
  //   path: "/u-old/:storeId/:orderId",
  //   name: "BillView",
  //   component: import("../views/BillView"),
  // },
  {
    path: "/u/:store/:order",
    name: "FastPay",
    component: FastPay,
  },
  // {
  //   path: "/u/:store/:order",
  //   name: "FastPay",
  //   component: FastPayRedirect,
  // },
  // {
  //   path: "/tips/:userId/:reviewId?",
  //   name: "HomeView",
  //   component: import("../views/HomeView"),
  // },
  {
    path: "/:user/:review?",
    name: "TipsPage",
    component: TipsPage,
  },
  // {
  //   path: "/:user/:review?",
  //   name: "TipsPageRedirect",
  //   component: TipsPageRedirect,
  // },
  {
    path: "/*",
    redirect: "https://app.rhmt.uz",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name === 'MarketplacePage') {
    sessionStorage.setItem('scrollPosition', window.scrollY.toString());
  }
  next();
});

router.afterEach(() => {
  const scrollPosition = sessionStorage.getItem('scrollPosition');
  if (scrollPosition !== null) {
    Vue.nextTick(() => {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    });
  }
});

export default router;
