<template>
  <div :style="{'--menu-item-color': btnColor}">
    <van-tabs
        v-if="products.length && showMenu"
        class="tab-style3 van-overflow-hidden"
        :class="[isMarketplace ? 'tab-style3--marketplace' : '', !!search && 'searching' ]"
        v-model="activeTab"
        swipe-threshold="1"
        :offset-top="adModal && stickyEnabled ? 154 : 55"
        swipeable
        scrollspy
        sticky
    >
      <template v-if="activeItemsCount">
        <van-tab
            v-for="product in activeProducts"
            :id="product.id"
            :key="product.id"
            :name="product.name"
            :title="product.name"
            :style="{ padding: product.id === 'filter' ? 0 : undefined }"
        >
          <template #title>
            <span v-if="product.id === 'filter'">
              <img
                  @click.stop="$emit('category')"
                  style="display: block;height: 20px;"
                  src="@/assets/images/filter.svg"
              />
            </span>
            <span v-else>{{ product.name }}</span>
          </template>
          <div v-if="product.id == 'filter'" style="padding: 0"></div>
          <div
              class="m-title plr-20 mb-10"
              v-else-if="product.items && product.items.length"
          >
            {{ product.name }}
          </div>
          <div class="foods-list" v-if="product.items && product.items.length">
            <div class="col-6 col-sm-4" v-for="item in product.items" :key="item.id">
              <div
                  class="fooditem style-3"
                  :class="{
                    active: inCart(item),
                    disabled: isDisabled(item)
                  }"
                  @click="showOrderModal(item)"
              >
                <div class="item-top">
                  <div class="item-image-wrapper">
                    <img :src="getImage(item, 'small')" alt=""/>
                    <template>
                      <div v-if="item.modifiers.length && inCart(item)" class="overlay">
                        {{ inCart(item) }}
                      </div>
                      <div v-if="!item.modifiers.length && inCart(item)" class="overlay">
                        {{ inCart(item) }}
                      </div>
                    </template>
                  </div>
                  <div class="fooditem_desc">
                    <div class="grey mb-5 f-14" v-if="item.weight">
                      <!-- {{ item.weight | weight($i18n.locale) }} -->
                      {{ item.weight }} {{ item.unit }}
                    </div>
                    <div class="fooditem_desc_title mb-5 f-14">
                      {{ item.name }}
                    </div>
                    <div class="fooditem_price f-14">
                      {{ item.price | money($i18n.locale) }}
                    </div>
                  </div>
                </div>
                <div v-if="canOrder" class="item-bottom">
                  <div v-if="!inCart(item)" @click="handleAddToCart(item)" class="add-cart-btn space-between">
                    <div class="hide">-</div>
                    <span v-if="item.modifiers.length && item.modifierRequired" class="add-cart-text">Добавить</span>
                    <span v-else @click.stop="addItemToCart(item)" class="add-cart-text">Добавить</span>
                    <div v-if="item.modifiers.length && item.modifierRequired">+</div>
                    <div v-else @click.stop="addItemToCart(item)">+</div>
                  </div>
                  <div v-else @click.stop="handleAddToCart(item)" class="add-cart-btn space-between">
                    <div @click.stop="removeItemFromCart(item)">-</div>
                    <span>{{ inCart(item) }}</span>
                    <div v-if="item.modifiers.length && item.modifierRequired">+</div>
                    <div v-else @click.stop="addItemToCart(item)">+</div>
                  </div>
                </div>
                <div v-else class="item-bottom">
                  <div class="add-cart-btn item-bottom">
                    {{ item.price | money($i18n.locale) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </template>
      <van-empty
          v-if="!activeItemsCount"
          image="search"
          :description="$t('not-found')"
      />
    </van-tabs>
    <van-empty v-else image="search" description="Продукты не найдены"/>
    <van-popup
        class="order-popup"
        v-model="orderModal"
        position="bottom"
        :style="{ maxHeight: '90%', zIndex: 3000 }"
        closeable
        @close="closeOrderModal"
        close-icon-position="top-right"
        close-icon="close"
    >
      <div
          v-if="activeItem.bigImageUrl"
          class="order-image"
          :style="getBackground(getImage(activeItem, 'big'))"
      >
      </div>
      <div class="order-modal-content" :class="{'has-desc': activeItem.description}">
        <h3 v-if="activeItem.description">
          {{ activeItem.description }}
        </h3>
      </div>
      <template v-if="activeItem.modifiers?.length">
        <div
            v-for="(modifier, index) in activeItem.modifiers"
            :key="modifier.id"
            class="order-modal-modifier"
        >
          <product-modifier
              :key="keyMod"
              :modifier="modifier"
              :modifier-temp="modifierTemp"
              :is-collapsed="isCollapsed(modifier, index)"
              :selected-modifier="selectedModifier(modifier)"
              :activeItem="activeItem"
              @can-add-order="canAddOrder = $event"
              @toggle-modifier="handleToggleModifier"
              @switch-modifier="handleSwitchModifier"
              @change-modifier="handleChangeModifier"
          />
        </div>
      </template>
      <div class="footer" :style="{paddingTop: !activeItem.bigImageUrl ? '50px' : ''}">
        <div class="top d-flex justify-content-between align-items-end">
          <div class="title" style="max-width: 70%">
            <span>{{ activeItem.weight }} {{ activeItem.unit }}</span>
            <h4>{{ activeItem.name }}</h4>
          </div>
          <div class="price">
            {{ calcMenuPrice | money($i18n.locale) }}
          </div>
        </div>
        <div class="bottom" v-if="canOrder">
          <van-stepper
              v-if="activeItem.modifiers?.length"
              v-model="stepper"
              button-size="22"
              disable-input
              @minus="handleStepper(activeItem, 'minus')"
              @plus="handleStepper(activeItem, 'plus')"
          />
          <van-stepper
              v-else
              :key="keyStep"
              :value="inCart(activeItem)"
              button-size="22"
              disable-input
              @change="onChangeStep($event)"
              @minus="handleStepper(activeItem, 'minus')"
              @plus="handleStepper(activeItem, 'plus')"
          />
          <van-button :disabled="!canAddOrder" @click="handleAddOrder">
            {{
              statusOfBtn === 'not-changed' ?
                  'Готово' : statusOfBtn === 'changed' ?
                  'Сохранить' : 'Добавить'
            }}
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Button, Empty, Popup, Stepper, Tab, Tabs,} from "vant";
import Item from "@/models/Item";
import ProductModifier from "@/components/menu/ProductModifier";
import {localeStorageGetItem, localeStorageSetItem} from "@/helpers/useLocalStorage";

export default {
  name: "ProductsBlock",
  props: {
    showMenu: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    isMarketplace: {
      type: Boolean,
      default: false,
    },
    stickyEnabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['category'],
  data() {
    return {
      activeItem: new Item({}),
      orderModal: false,
      radioModel: null,
      hasModifiers: false,
      activeTab: "",
      timeoutDay: 1,
      stepper: 1,
      stepper2: 1,
      stepper3: 1,
      keyMod: 1,
      keyStep: 1,
      cartTemp: [],
      modifierTemp: [],
      isCountMinus: false,
      canAddOrder: false,
      stepCount: 0,
    };
  },
  methods: {
    ...mapActions(["addToCart", "addToCartWithoutMod", "removeFromCart", "addModToCart"]),
    ...mapMutations(["CART_CLEAR", "CART_REMOVE_MINUS"]),
    handleAddToCart(item) {
      this.cartTemp = [];
      this.modifierTemp = [];
      item.modifiers.forEach((modifier) => {
        modifier?.items.forEach((modifierItem) => {
          if (modifier?.down > 0) {
            if (modifierItem?.available != 0) {
              if (!this.modifierTemp.find(temp => temp['group_id'] == modifierItem.group_id)) {
                this.modifierTemp.push({
                  ...modifierItem,
                  quantity: 1
                })
              }
            }
          }
        })
      })
      this.stepCount = 0;
      this.isCountMinus = false;
      this.activeItem = item;
      if (item.modifiers.length) {
        this.cartTemp.push({...item, idx: Date.now(), modifiers: []});
      }
      if (!item.modifiers.length && !this.inCart(item)) {
        this.addToCartTemp2(item, 'plus');
      }
      this.hasModifiers = true;
      this.orderModal = true;
    },
    handleStepper(item, type) {
      if (item.modifiers.length) {
        this.addToCartTemp(item, type);
      } else {
        this.addToCartTemp2(item, type);
      }
    },
    addToCartTemp(item, type) {
      if (type === "plus") {
        const currentOrder = this.cartTemp.find(f => f.id === item.id);
        if (currentOrder) {
          // todo need work on modifiers
          this.cartTemp.push({...item, idx: Date.now(), modifiers: currentOrder.modifiers});
        }
      } else {
        this.cartTemp.pop();
      }
    },
    addToCartTemp2(item, type) {
      if (type === "plus") {
        this.cartTemp.push(item);
        this.isCountMinus = false;
      } else {
        this.cartTemp.pop();
        this.isCountMinus = true;
      }
    },
    onChangeStep(event) {
      this.stepCount = event;
    },
    handleToggleModifier(item) {
      const modifier = this.modifierTemp.find(m => m.id === item.id);
      if (!modifier) {
        this.modifierTemp.push({
          ...item,
          quantity: 1
        });
      } else {
        const index = this.modifierTemp.findIndex(f => f.id === item.id);
        if (index > -1) this.modifierTemp.splice(index, 1);
      }
    },
    handleSwitchModifier(item) {
      const modifier = this.modifierTemp.find(m => m['group_id'] === item['group_id']);
      if (modifier) {
        const temp = this.modifierTemp.find(m => m.id === modifier.id)
        this.modifierTemp.splice(this.modifierTemp.indexOf(temp), 1);
      } else {
        if (this.modifierTemp.length) {
          this.modifierTemp.reduce(temp => temp['group_id'] === item['group_id'])
        }
      }
      this.modifierTemp.push({...item, quantity: 1})
    },
    handleChangeModifier({item, quantity}) {
      const modifier = this.modifierTemp.find(m => m.id === item.id);
      if (modifier) {
        modifier.quantity = quantity;
      } else {
        this.modifierTemp.push({
          ...item,
          quantity
        });
      }
    },
    addItemToCart(item) {
      this.addToCartWithoutMod({ ...item, modifiers: [] });
      this.setCartTimeStamp();
    },
    removeItemFromCart(item, all = false) {
      this.removeFromCart({item, all});
      this.setCartTimeStamp();
    },
    isDisabled(item) {
      return item.disabled || (item.available !== null && item.available === 0)
    },
    showOrderModal(item) {
      if (this.isDisabled(item)) {
        return;
      }
      this.cartTemp = [];
      this.canAddOrder = !item.modifiers.length || !item.modifierRequired;
      this.activeItem = item;
      this.handleAddToCart(item);
      this.stepCount = this.inCart(item);
      this.radioModel = item;
      this.keyMod++;
      this.keyStep++;
      this.orderModal = true;
    },
    closeOrderModal() {
      this.keyMod++;
      this.orderModal = false;
      this.cartTemp = [];
      this.stepper = 1;
    },
    handleAddOrder() {
      if (this.isCountMinus) {
        this.CART_REMOVE_MINUS({item: this.activeItem, minusCount: this.stepCount});
      } else {
        if (this.modifierTemp?.length) {
          this.cartTemp.map(m => {
            m.modifiers = this.modifierTemp;
          });
        }
        this.addToCart(this.cartTemp);
      }
      this.closeOrderModal();
    },
    getBackground(image) {
      return `background-image: url('${image}')`;
    },
    getImage(item, size) {
      if (!item) return;
      let image = "";
      if (size === "big") image = item.bigImageUrl;
      if (size === "small") image = item.imageUrl;
      if (image) image = image.replace("http:", "https:");
      if (!image) {
        image = '/img/dummy.png';
      }
      return image;
    },
    inCart(item) {
      return this.cart.filter((el) => el.id === item.id).length;
    },
    selectedModifier(modifier) {
      if (modifier.down > 0) {
        this.canAddOrder = true
        return modifier.items.find((modifier) => modifier.available != 0)?.id
      }
      return null;
    },
    isCollapsed(item, index) {
      if (item.down > 0)
        return true;
      else
        return index === 0;
    },
    setCartTimeStamp() {
      this.timeoutDay = 1;
      let timeout;
      if (localeStorageGetItem("timeIsCart") == 0) {
        timeout = new Date().getTime() + this.timeoutDay * 60 * 1000 * 60 * 24;
        localeStorageSetItem("timeIsCart", timeout);
      } else {
        timeout = localeStorageGetItem("timeIsCart");
      }
      if (new Date().getTime() > timeout) {
        localeStorageSetItem("timeIsCart", 0);
        this.CART_CLEAR();
      }
    },
  },
  computed: {
    ...mapGetters(["cart", "products", "canOrder", "adModal", "btnColor"]),
    statusOfBtn() {
      let text = "";
      const order = this.inCart(this.activeItem) !== this.stepCount;

      if (!this.activeItem.modifiers?.length && this.inCart(this.activeItem)) {
        if (order) text = "changed";
        else text = "not-changed";
      } else text = "add";

      return text;
    },
    calcMenuPrice() {
      let price = this.activeItem.price;
      if (this.modifierTemp?.length) {
        const calc = this.modifierTemp.reduce((sum, item) => sum + (item?.price * item.quantity), 0);
        price += calc;
      }
      return price;
    },
    activeProducts() {
      let list = []
      if (this.products) {
        list.push({
          id: 'filter',
          items: [],
          name: 'filter'
        })
        list.push(...structuredClone(this.products))
      }
      return list.filter((product, key) => {
        if (this.products[key - 1]) {
          product.items = this.products[key - 1].items.filter((item) => {
            return item.name.toLowerCase().includes(this.search.toLowerCase());
          });
        }
        return product.items.filter((item) => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        });
      });
    },
    activeItemsCount() {
      return this.activeProducts
          .map((product) => {
            return product.items.length;
          })
          .reduce((accumulator, count) => {
            return accumulator + count;
          }, 0);
    },
  },
  created() {
    this.setCartTimeStamp();
  },
  components: {
    ProductModifier,
    "van-tab": Tab,
    "van-tabs": Tabs,
    "van-empty": Empty,
    "van-popup": Popup,
    "van-button": Button,
    "van-stepper": Stepper,
  },
};
</script>

<style scoped></style>
