<template>
  <div class="catalog" id="catalog">
    <loading-block v-if="menuLoading"/>
    <catalog-menu-header
        ref="menuHeader"
        @delivery-modal="showbranchAddress"
        :logo="logo"
        :show-address="true"
        :store-name="storeName"
        :key="menuHeaderKey"
        :search-value="search"
        :is-marketplace="true"
        :current-store="currentStore"
    />
    <div class="vendor-content"></div>
    <div v-if="false" id="map"></div>
    <yandex-map
        v-if="false"
        :settings="mapSettings"
        :coords="center"
        :zoom="15"
        :controls="['zoomControl', 'geolocationControl']"
        @click.stop="() => {}"
        style="
          height: 30%;
          width: 100%;
          position: fixed;
          top: 0;
          z-index: 5;
          left: unset;
          max-width: 768px;
          margin: 0 auto;
        "
    >
      <ymap-marker
          :marker-id="markerId"
          :coords="center"
          :marker-type="'placemark'"
          :icon="{ content: iconAddress }"
      />
    </yandex-map>
    <brand-detail
        v-if="Object.keys(currentStore).length"
        :currentStore="currentStore"
        :store-name="storeName"
        @open-branch="branchAddress = true"
        @open-yandex-modal="openYandexModal"
        @open-map-modal="openMapModal"
    />
    <swipe-modal
        :modal="menuModal"
        @close="menuModal = false"
    >
      <template v-slot:body>
        <call-modal/>
      </template>
    </swipe-modal>
    <swipe-modal
        class="float-menu-modal"
        :modal="categoryModal"
        @close="categoryModal = false"
    >
      <template v-slot:body>
        <ul class="float-menu" v-if="products.length && showMenu">
          <li v-for="category in products" :key="category.id">
            <a href="#" @click.prevent="goToCategory(category)" class="link">{{
                category.name
              }}
            </a>
          </li>
        </ul>
      </template>
    </swipe-modal>
    <van-popup
        class="sales-modal h-100 market-sales-modal"
        v-model="storyModal"
        position="bottom"
        @opened="storyModal = true"
        :style="{ maxHeight: '100%' }"
    >
      <CatalogStories
          :key="storyKey"
          :stories-from="stories"
          :showInnerContent="true"
          :showOuterContent="true"
          :duration="5000"
          :currentIndex="currentStoryIndex"
          :viewed="false"
          :modal="storyModal"
          @closeStories="storyModal = false"
          @сurrentImageEnd="currentImgEnd"
          @сurrentAllImagesEnd="currentAllImagesEnd"
          @allStoriesEnd="storyKey++"
      >
      </CatalogStories>
    </van-popup>
    <van-popup
        v-model="yandexModal"
        class="yandex-modal order-info-popup market-filter-modal"
        position="bottom"
        @opened="yandexModal = true"
        :style="{ maxHeight: '100%' }"
    >
      <div class="yandex-modal-content">
        <h2 class="pl-20 pr-20 mb-10 mt-0">{{$t('trip-forecast')}}</h2>
        <div v-if="Object.keys(tariffData).length"
             class="catalog-tags pt-0 pb-0 mb-0 mt-0">
          <swiper
              class="stickers-swiper"
              :breakpoints="swiperStickerOptions.breakpoints"
              :space-between="10"
              :loop="false"
          >
            <swiper-slide
                v-for="(tariff, index) in tariffData.options"
                :key="index"
            >
              <div
                  @click="selectTariff(tariff)"
                  class="catalog-tag catalog-tag-yandex mb-10 mt-0"
                  :class="yandexTimeActive === tariff.class_name ? 'active' : ''"
              >
                {{ tariff.class_text }}
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                  @click="selectTariff('fast')"
                  class="catalog-tag catalog-tag-yandex mb-10 mt-0"
              >{{$t('fast')}}
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div v-if="Object.keys(currentTariffData).length" class="div pl-30 pr-30 mb-10">
          <van-skeleton round :row="3" class="mb-20 p-0" :loading="loadingTariff">
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18">
                <path fill="grey"
                      d="M201.239-193.174v37.326q0 19.448-13.974 33.061T154.4-109.174q-19.03 0-32.509-13.663-13.478-13.663-13.478-33.25V-463q0-6.299 1.12-12.358 1.119-6.06 3.119-12.077l76.761-231.761q8.239-22.956 28.224-37.413 19.984-14.456 44.841-14.456H370v-41q0-15.636 11.271-26.894 11.27-11.259 27.881-11.259h144.696q15.636 0 26.894 11.259Q592-827.701 592-812.065v41h106.522q23.68 0 43.753 14.456 20.073 14.457 28.551 37.413l76.522 231.761q2 6.017 3.239 12.077 1.239 6.059 1.239 12.358v307.152q0 19.448-13.953 33.061-13.954 13.613-32.815 13.613-18.842 0-33.07-13.955-14.227-13.954-14.227-33.958v-36.087H201.239Zm3.631-345.152h550.5L701.848-699.37H258.152L204.87-538.326Zm80.83 224.652q23.496 0 39.876-16.519 16.381-16.519 16.381-38.975 0-24.122-16.548-40.836-16.547-16.713-38.855-16.713-24.453 0-40.927 16.617-16.475 16.617-16.475 40.141 0 23.524 16.436 39.905 16.436 16.38 40.112 16.38Zm388.985 0q24.213 0 40.808-16.519 16.594-16.519 16.594-38.975 0-24.122-16.556-40.836-16.555-16.713-39.991-16.713-23.736 0-39.997 16.617-16.26 16.617-16.26 40.141 0 23.524 16.427 39.905 16.428 16.38 38.975 16.38Z"/>
              </svg>
              <div class="ml-10">{{ $t('market.tariff') }}: {{ currentTariffData.class_text }}</div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey"
                      d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
              </svg>
              <div class="ml-10">{{ $t('market.waiting-time') }}:
                <span>{{ (currentTariffData.waiting_time / 60).toFixed(1) }} мин</span></div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey"
                      d="M352.29-111.848q-70.232 0-118.087-48.259-47.855-48.259-47.855-117.523v-341.174q-35.239-14.353-57.62-43.848-22.38-29.496-22.38-67.015 0-50.746 34.481-85.116 34.481-34.369 83.643-34.369 50.401 0 84.791 34.619 34.389 34.62 34.389 84.533 0 37.608-22.88 67.282-22.881 29.675-57.12 43.914v341.182q0 36.903 25.534 62.686 25.533 25.784 63.133 25.784 37.927 0 63.728-25.763 25.801-25.762 25.801-62.636V-682.37q0-70.152 47.735-118.467 47.736-48.315 117.968-48.315 70.471 0 118.906 48.315 48.434 48.315 48.434 118.467v340.174q34 15.239 57 44.788 23 29.548 23 66.995 0 49.707-34.709 84.136-34.71 34.429-84.752 34.429-49.303 0-83.693-34.489-34.389-34.489-34.389-84.007 0-37.466 22.38-67.42 22.381-29.953 57.62-44.432V-682.37q0-36.948-26.271-63.213t-63.396-26.265q-36.927 0-62.728 26.265-25.801 26.265-25.801 63.213v404.74q0 69.264-48.315 117.523T352.29-111.848Z"/>
              </svg>
              <div class="ml-10">{{ $t('market.distance') }}: <span>{{
                  (tariffData.distance / 1000).toFixed(1)
                }} км</span></div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey"
                      d="M499.87-82.848q-23.483 23.674-56.013 23.674-32.531 0-55.205-23.674L83.609-387.89q-24.957-24.957-24.837-57.489.12-32.533 24.076-56.729l376.804-375.043q11.747-12.648 26.069-17.541 14.322-4.894 30.04-4.894h306.282q33.674 0 55.609 21.767 21.935 21.768 21.935 55.777V-516q0 14.895-5.098 29.143-5.098 14.248-16.815 26.966L499.87-82.848Zm216.469-578.348q22.226 0 39.128-16.958 16.903-16.959 16.903-40.185 0-22.226-16.959-39.128-16.958-16.903-39.185-16.903-23.226 0-40.128 16.959-16.902 16.958-16.902 39.185 0 23.226 16.958 40.128 16.959 16.902 40.185 16.902Z"/>
              </svg>
              <div class="ml-10">{{ $t('market.price') }}<span style="color: red">*</span>:
                <span>{{ currentTariffData.price_text }}</span></div>
            </div>
            <div class="item-label mb-5 d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960" width="17">
                <path fill="grey"
                      d="M520.478-495.587v-161.261q0-15.186-10.663-25.952-10.662-10.765-26.219-10.765-15.556 0-26.554 10.765-10.999 10.766-10.999 25.952v176.131q0 8.543 2.62 15.461 2.62 6.917 7.859 11.973l139.087 143.087q10.611 12 26.903 12 16.292 0 28.412-12 10.119-10 10.619-26.598.5-16.597-10.739-27.836L520.478-495.587ZM480.309-62.804q-85.188 0-161.857-32.988t-133.165-89.476q-56.496-56.487-89.49-133.144-32.993-76.656-32.993-161.837 0-85.438 33.1-161.463 33.098-76.026 89.66-132.609 56.563-56.583 133.095-89.848 76.533-33.266 161.617-33.266 85.446 0 161.452 33.262 76.005 33.261 132.595 89.85 56.589 56.59 89.85 132.622 33.262 76.032 33.262 161.509 0 85.476-33.266 161.771-33.265 76.294-89.848 132.857-56.583 56.562-132.576 89.66-75.993 33.1-161.436 33.1Z"/>
              </svg>
              <div class="ml-10">{{ $t('market.time') }}: <span>{{ tariffData.time_text }}</span></div>
            </div>
            <div style="font-size: 12px; color: grey; margin-top: 12px"><span
                style="color: red">*</span>{{ $t('market.notes') }}
              <a href="https://taxi.yandex.ru/">taxi.yandex.ru</a></div>
          </van-skeleton>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <button @click="yandexModal = false" class="btn-yandex btn-yandex--1">{{ $t('market.cancel') }}</button>
        <button @click="yandexOrder" class="btn-yandex btn-yandex--2">{{ $t('market.order') }}</button>
      </div>
    </van-popup>
    <van-popup
        v-model="mapModal"
        class="map-modal order-info-popup market-filter-modal"
        position="bottom"
        @opened="mapModal = true"
        :style="{ maxHeight: '100%' }"
    >
      <van-cell-group>
        <van-cell is-link>
          <template #title>
            <div @click="openMapApp('yandex-taxi')" class="d-flex align-items-center">
              <div class="item item-yandex mr-15"><img src="/yandex-taxi.jpeg" alt=""></div>
              <span class="custom-title">Yandex.Taxi</span>
            </div>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <div @click="openMapApp('apple-map')" class="d-flex align-items-center">
              <div class="item item-yandex mr-15"><img src="/apple-map.png" alt=""></div>
              <span class="custom-title">Apple Maps</span>
            </div>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <div @click="openMapApp('google-map')" class="d-flex align-items-center">
              <div class="item item-yandex mr-15"><img src="/google-map.png" alt=""></div>
              <span class="custom-title">Google Maps</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-popup>
    <catalog-branch-address
        :modal="branchAddress"
        :coordinators="mapCoords"
        :branches="branches"
        :isDeviceOnMobile="!!(userAgent.iosAndroid() && hasNavigatorShare)"
        @close="hideBranchAddress"
        @open-yandex-modal="openYandexModal"
        @open-map-modal="openMapModal"
    />
    <div class="float-buttons-wrap left">
      <div
          v-if="showMenu"
          class="menu-button"
          @click="$router.push({ name: 'DeliveryPage', params: { brand: $route.params.brand, store: $route.params.store } })"
      >
        <span class="menu-title">{{$t('menu-title')}}</span>
      </div>
    </div>
    <div class="float-buttons-wrap right">
      <van-button
          v-if="!menuLoading && canReserve"
          class="menu-button"
          @click="moveToBook"
      >
        <span class="menu-title">{{$t('order-title')}}</span>
      </van-button>
    </div>
    <navigation-menu :market="true" @change-zindex="changeZindex"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Button, Cell, CellGroup, Popup, Skeleton} from "vant";
import {DeviceUUID} from "device-uuid";
import {translate} from "@/utils/animate"
import SwipeModal from "@/components/menu/SwipeModal.vue";
import BrandDetail from "@/components/menu/BrandDetail.vue";
import LoadingBlock from "@/components/menu/Loading.vue";
import NavigationMenu from "@/components/menu/NavigationMenu.vue";
import CatalogBranchAddress from "@/components/menu/CatalogBranchAddress.vue";
import CatalogMenuHeader from "@/components/menu/CatalogMenuHeader.vue";
import CatalogStories from "@/components/marketplace/CatalogStories.vue";
import {maxlength} from "caniuse-lite/data/features";
import apiClient from "@/services/axios";
import {localeStorageGetItem} from "@/helpers/useLocalStorage";
import {useUserAgent} from "@/helpers/useUserAgent";
import CallModal from "@/components/menu/CallModal.vue";
import "swiper/swiper-bundle.css";
import {Swiper, SwiperSlide} from "swiper-vue2";
import {yandexMap, ymapMarker} from "vue-yandex-maps";

export default {
  title: "QR Меню | Каталог",
  name: "CatalogPage",
  provide() {
    return {
      services: this.services
    }
  },
  data() {
    return {
      yandexTags: [
        // {type: "Start", label: 50},
        // {type: "Standard", label: 60},
        // {type: "Comfort", label: 70},
        {type: "Business", label: 80},
        {type: "Vip", label: 90},
      ],
      swiperStickerOptions: {
        breakpoints: {
          0: {
            slidesPerView: 3.5
          },
          // 481: {
          //   slidesPerView: 4.5
          // },
          // 576: {
          //   slidesPerView: 5
          // }
        }
      },
      mapSettings: {
        apiKey: "e9fe7cab-eb17-4480-976c-a9bd1756a5c1",
        lang: this.$i18n.locale,
        coordorder: "latlong",
        enterprise: true,
        version: "2.1",
      },
      center: [41.31115, 69.279737],
      coords: [41.31115, 69.279737],
      mapCoords: [],
      branches: [],
      iconAddress: "",
      markerId: 0,
      storyModal: false,
      loadingTariff: false,
      yandexModal: false,
      currentBranchCoords: [],
      yandexTimeActive: "start",
      mapModal: false,
      storyKey: 0,
      storeName: "",
      isClickedMorePhoto: false,
      maxlengthImages: 6,
      stories: [],
      storyImages: [
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3568095/03320288962b8c4d982b2d00c23b2686-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/2050703/a9301f137950ee9ec5c79599bacc02a7-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3377781/4e1aee86ad8d385b76a0edc64a29186f-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3439028/06b9610ccc68b29db0b92d4a9f6da731-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3538649/b7ed5724ffe10cee6fd73cb69b758f42-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3568095/f45668519ee7ba0cbe893b1aa22cba09-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/2050703/e9ffeaa467b0ab62520f39c00eed2a8d-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3806023/478d895cc6ac23233bf84816465b8d98-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3595156/d3bbffed3b111bfed5411b06867a7ba4-300x300.jpg",
            },
          ],
        },
        {
          images: [
            {
              url: "https://eats.yandex.com/images/3807631/ff0c246bb26d42be4fcb900e81807272-300x300.jpg",
            },
          ],
        },
      ],
      currentStoryIndex: 0,
      currentStore: {},
      logo: "/img/nologo.png",
      search: "",
      searchFood: "",
      cartModal: false,
      branchAddress: false,
      checkoutModal: false,
      authModal: false,
      orderInfoModal: false,
      menuModal: false,
      categoryModal: false,
      menuHeaderKey: 0,
      showMenu: false,
      deliveryPrice: 20000,
      storeSelected: false,
      showMoreDesc: false,
      reachedBottom: false,
      canReserve: false,
      orderType: "delivery",
      showMoreText: this.$t("catalog.show-more"),
      userAgent: useUserAgent(),
      currentTariffData: {},
      tariffData: {},
      services: [
        {id: 1, text: "delivery", imgUrl: "delivery-courier.svg"},
        {id: 2, text: "takeaway", imgUrl: "takeaway.svg"},
        {id: 3, text: "on_table", imgUrl: "table.svg"},
      ],
      clientMenuAdd: [],
      menuBranchAddress: "",
      currentUserAddress: "",
    };
  },
  created() {
    let uuid = new DeviceUUID().get();
    this.setDeviceUuid(uuid);
  },
  mounted() {
    this.getCurrentPositionFunc();
    this.getProducts();
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", this.$route.fullPath);
    scrollFn();
    function scrollFn() {
      let startY = 0;
      let countOfTouch = 0;
      let movingDown = true;
      const catalog_menu_header = document.getElementById('catalog_menu_header');
      window.addEventListener('scroll', ()=> {
        const scrollY = window.scrollY || window.pageYOffset;
        if (scrollY < 1) {
          increaseSmall();
          setTimeout(() => decrease(), 200);
        } else {
          decrease();
        }
      })
      window.addEventListener('touchstart', (event) => {
        const touch = event.touches[0];
        startY = touch.clientY;
        countOfTouch = 1;
      });
      window.addEventListener('touchmove', (event) => {
        const scrollY = window.scrollY || window.pageYOffset;
        const touch = event.touches[0];
        const deltaY = touch.clientY - startY;
        countOfTouch += 1;
        if (deltaY > 0) {
          movingDown = true;
        } else if (deltaY < 0) {
          movingDown = false;
        }

        if (movingDown && scrollY < 1) {
          increase();
        }

        startY = touch.clientY;
      });
      window.addEventListener('touchend', () => {
        decrease();
        startY = 0;
        countOfTouch = 0;
      });
      function increaseSmall() {
        catalog_menu_header.style.paddingTop = `80px`;
        catalog_menu_header.style.backgroundSize = `105%`;
      }
      function increase() {
        const height = Math.floor((countOfTouch + 60) * 1.2);
        const percent = Math.floor((countOfTouch + 100) * 1.08);
        if (height <= 130) {
          catalog_menu_header.style.paddingTop = `${height}px`;
        }
        if (percent <= 135)
          catalog_menu_header.style.backgroundSize = `${percent}%`;
      }
      function decrease() {
        catalog_menu_header.style.paddingTop = `60px`;
        catalog_menu_header.style.backgroundSize = `100%`;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SET_NAVIGATION_MENU_HOME_ROUTE", from.fullPath);
    next();
  },
  methods: {
    ...mapActions([
      "addToCart",
      "removeFromCart",
      "makeOrder",
      "showMenuLoading",
      "fetchProducts",
      "setCanOrder",
      "setDeviceUuid",
    ]),
    observerFunc() {
      const callbackDesc = (entries) => {
        if (!entries[0].isIntersecting) {
          this.showMoreDesc = false;
          translate(this.$refs.descContent, this.showMoreDesc);
        }
      };
      const callbackHeader = (entries) => {
        if (entries[0].isIntersecting) {
          this.maxlengthImages = 6;
          this.isClickedMorePhoto = false;
        }
      };
      const observerDesc = new IntersectionObserver(callbackDesc, {rootMargin: "0px", threshold: 1.0});
      const observerHeader = new IntersectionObserver(callbackHeader, {rootMargin: "0px", threshold: 1.0});
      if (this.$refs.observer) {
        observerDesc.observe(this.$refs.observer);
        if (this.$refs.menuHeader) {
          observerHeader.observe(this.$refs.menuHeader.$el);
        }
      }
    },
    async getAddress(value) {
      let codes = value?.split(',');
      // const apiKey = "57f9f54b-0274-4821-a5e6-c86eecd226ee";
      // const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=Тверская+6`;
      // const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&geocode=37.611347,55.760241`;
      // const response = await fetch(url, {
      //   method: 'POST'
      // });
      // const data = response.json();
      return codes;
    },
    openYandexModal(array) {
      this.currentBranchCoords = array;
      const params = {
        "id": "ak210712",
        "key": "TvKFGxjIwLwPIZjPtdpmdOSHiFcbuVpJhUpFk",
        "coords": `${this.marketCatalogCurrentCoords[1]},${this.marketCatalogCurrentCoords[0]}~${this.currentBranchCoords[1]},${this.currentBranchCoords[0]}`,
        "class": "start,econom,business,vip",
      }
      apiClient.get('/qr-menu/taxi-info', {params}).then(res => {
        this.tariffData = res.data?.data;
        const business = this.tariffData.options.find(t => t.class_name === 'business');
        this.selectTariff(business);
        this.yandexModal = true;
      }).catch(e => {
        console.log('Error getting taxi route: ', e);
      })
    },
    yandexOrder() {
      const params = {
        "start-lat": this.marketCatalogCurrentCoords[0],
        "start-lon": this.marketCatalogCurrentCoords[1],
        "end-lat": this.currentBranchCoords[0],
        "end-lon": this.currentBranchCoords[1],
        "level": this.currentTariffData.class_level,
        "ref": "ak210712",
        "appmetrica_tracking_id": "1178268795219780156",
      };
      const searchParams = new URLSearchParams(params);
      const url = `https://3.redirect.appmetrica.yandex.com/route?${searchParams.toString()}`

      if (this.useragent === 'multicard')
        window.flutter_inappwebview.callHandler('browser', {url})
      else
        window.open(url, "_blank");
    },
    getCurrentPositionFunc() {
      if (this.useragent === 'multicard') {
        window.flutter_inappwebview.callHandler('getLocation').then(res => {
          if (res?.latitude || res?.longitude) {
            this.userCoords = [res.latitude, res.longitude];
            this.$store.commit("SET_MARKET_CATALOG_COORDS", this.userCoords);
          }
        })
      } else {
        navigator.geolocation.getCurrentPosition(
            this.successCallback,
            this.errorCallback,
        );
      }
    },
    async successCallback(position) {
      navigator.permissions.query({name: 'geolocation'}).then(async (permissionStatus) => {
        this.allowedUserLocation = permissionStatus.state === "granted"
        this.userCoords = [position.coords.latitude, position.coords.longitude];
        this.$store.commit("SET_MARKET_CATALOG_COORDS", this.userCoords);
      });
    },
    errorCallback(error) {
      console.log('Error getting position: ', error)
    },
    openMapApp(type) {
      if (this.userAgent.iosAndroid()) {
        switch (type) {
          case "yandex-taxi":
            location.replace("https://taxi.yandex.uz");
            break;
          case "apple-map":
            location.replace("https://taxi.yandex.uz");
            break;
          case "google-map":
            location.replace("https://taxi.yandex.uz");
            break;
        }
        console.log('Opened Iphone or Android device')
      }
    },
    selectTariff(value) {
      if (value === 'fast') {
        this.yandexTimeActive = value;
        const findOldest = (list) => {
          const minTime = Math.min(...list.map(obj => obj.waiting_time));
          const resultWithTime = list.filter(obj => obj.waiting_time === minTime);
          const maxPrice = Math.max(...resultWithTime.map(obj => obj.price));
          return resultWithTime.find(obj => obj.price === maxPrice);
        };
        const result = findOldest(this.tariffData.options);
        this.currentTariffData = this.tariffData.options.find(t => t.class_name === result.class_name);
        this.yandexTimeActive = this.currentTariffData.class_name;
      } else {
        this.yandexTimeActive = value.class_name;
        this.currentTariffData = value;
      }
      this.loadingTariff = true;
      setTimeout(() => {
        this.loadingTariff = false;
      }, 100);
    },
    openMapModal(menu_coords) {
      window.open(`yandexnavi://build_route_on_map?lat_from=${this.userCoords[0]}&lon_from=${this.userCoords[1]}&lat_to=${menu_coords[0]}&lon_to=${menu_coords[1]}`, '_blank')
    },
    openAddressModal() {
      this.branchAddress = true;
    },
    showMore() {
      this.showMoreDesc = !this.showMoreDesc;
      if (this.showMoreDesc) {
        translate(this.$refs.descContent, this.showMoreDesc);
      }
    },
    loadMorePhotos(e) {
      this.onMovePhotoStory(e, "none");
      this.maxlengthImages = this.storyImages.length;
      this.isClickedMorePhoto = true;
    },
    showbranchAddress: function () {
      this.branchAddress = true;
    },
    finishOrder() {
      if (!this.storeSelected) {
        this.showbranchAddress();
        return;
      }
      this.checkoutModal = false;
      this.cartModal = false;
      this.makeOrder({
        store_id: this.$route.query.s,
        items: this.cart,
        price: this.cartTotalPrice,
        deliveryPrice: this.deliveryPrice,
        type: "delivery",
      });
    },
    removeItemFromCart(item, all = false) {
      this.removeFromCart({item, all});
    },
    getImage(item, size) {
      if (!item) return;
      let image = "";
      if (size === "big") image = item.bigImageUrl;
      if (size === "small") image = item.imageUrl;
      if (image) image = image.replace("http:", "https:");
      return image;
    },
    getBackground(image) {
      return `background-image: url('${image}')`;
    },
    async getProducts() {
      let storeId = "";
      const coords = localeStorageGetItem("coords");
      if (this.$route.params.store) {
        this.storeSelected = true;
        storeId = this.$route.params.store;
      }
      this.fetchProducts({
        brand: this.$route.params.brand,
        store: storeId,
        coordinates: coords
      }).then((res) => {
        if (res) {
          this.currentStore = res;
          this.storeName = res.name;
          this.showMenu = res.menu === 1;
          this.logo = res.logo;
          this.mapCoords = res.coordinates?.split(",");
          this.canReserve = Boolean(res.can_reserve);
          if (res.address && res['branch-address']) {
            this.branches = res['branch-address'];
            this.branches.unshift({
              address: res.address,
              coordinates: res.coordinates,
              telegram: res.telegram,
              phone: res.phone,
              instagram: res.instagram
            });
          }
          this.setCanOrder(res.order === 1);
          this.$nextTick(() => {
            this.observerFunc();
          });
        }
      });
    },
    showCheckoutModal: function () {
      if (this.token) {
        this.checkoutModal = true;
        return true;
      }
      this.showAuthModal();
    },
    showAuthModal: function () {
      this.authModal = true;
    },
    closeAuthModal: function () {
      this.authModal = false;
    },
    showCartModal: function () {
      this.cartModal = true;
    },
    closeCartModal: function () {
      this.cartModal = false;
    },
    hideBranchAddress: function () {
      this.branchAddress = false;
      this.menuHeaderKey++;
    },
    closeOrderInfoModal: function () {
      this.orderInfoModal = false;
    },
    showOrderInfoModal: function () {
      this.orderInfoModal = true;
    },
    async goToCategory(category) {
      this.activeTab = await category.name;
      this.categoryModal = false;
      let block = await document.getElementById(category.id);
      await block.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    onClickTag(value) {
      this.$store.commit("UPDATE_QUERY_PARAMS_KITCHEN", value.id);
      const query = {
        ...this.$route.query,
        kitchens: this.$store.getters["queryParams"].kitchens
      };
      this.$router.push({path: "/", query}).catch(() => {
      });
    },
    searchUpdate(value) {
      this.search = value;
      const element = document.querySelector(".foods-list");
      if (element) {
        /* eslint-disable */
        // debugger
        const positionY = element.getBoundingClientRect().top + window.scrollY;
        if (value && positionY) {
          window.scroll({
            top: positionY - 235,
            behavior: "smooth"
          });
        }
      }
    },
    clearSearch() {
      this.search = "";
    },
    changeZindex(increase) {
      if (this.$refs.menuHeader) {
        this.$refs.menuHeader.changeZIndex(increase);
      }
      if (this.$refs.headerBottom) {
        this.$refs.headerBottom.changeZIndex(increase);
      }
    },
    onMovePhotoStory(event, index) {
      if (index === "none") event.stopPropagation();
      else {
        index = 0;
        this.storyKey++;
        this.stories = [];
        this.currentStoryIndex = index;
        const arr = [
          {
            images: [],
          },
        ];
        this.storyImages.map(item => {
          arr[0].images.push(item.images[0]);
        });
        this.stories = [...arr];
        this.storyModal = true;
      }
    },
    currentImgEnd(index) {
      this.currentStoryIndex = index;
    },
    currentAllImagesEnd(index) {
      this.stories[index].images[this.stories[index].images.length - 1].viewed = false;
    },
    moveToBook() {
      const coords = this.currentStore['branch-address'][0]['coordinates'];
      this.$store.commit('SET_BOOKING_COORDS', coords.split(','));
      this.$router.push({name: 'BookTable', params: {storeId: this.$route.params.store}});
    }
  },
  computed: {
    ...mapGetters([
      "cart",
      "order",
      "menuLoading",
      "products",
      "token",
      "address",
      "canOrder",
      "deviceUuid",
      "adModal",
      "marketCatalogCurrentCoords",
      "coordinates",
    ]),
    useragent() {
      return this.userAgent.getUserAgent()
    },
    maxlength() {
      return maxlength;
    },
    hasNavigatorShare() {
      return navigator.share;
    },
    cartTotalPrice() {
      if (!this.cart || !this.cart.length) return 0;
      let price = 0;
      this.cart.forEach((cartItem) => {
        if (cartItem.item) {
          price += cartItem.item.price * cartItem.count;
        }
      });
      return price;
    },
  },
  watch: {
    token(value) {
      if (value) {
        this.authModal = false;
        this.checkoutModal = true;
      }
    },
  },
  components: {
    CallModal,
    NavigationMenu,
    LoadingBlock,
    CatalogMenuHeader,
    SwipeModal,
    CatalogStories,
    CatalogBranchAddress,
    BrandDetail,
    Swiper, SwiperSlide,
    yandexMap, ymapMarker,
    "van-popup": Popup,
    "van-cell": Cell,
    "van-cell-group": CellGroup,
    "van-skeleton": Skeleton,
    "van-button": Button,
  },
};
</script>

<style scoped lang="scss">

.tr-group {
  display: block;
  transition: all .3s ease 0s;
}

.tr-group-enter {
  opacity: 0;
}

.tr-group-leave-to {
  opacity: 1;
}

.tr-group-leave-active {
  //position: absolute;
}
</style>
